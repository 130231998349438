<template>
  <div class="d-flex flex-column justify-center" style="width: 100%">
    <v-container style="max-width: 800px">
      <div class="mb-16">
        <h2 class="mb-8 text-center">Yhteystiedot</h2>
        <div
          class="d-md-flex"
          style="justify-content: space-between; align-items: center"
        >
          <div class="mr-8" style="flex-grow: 1">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1961.7797624337672!2d22.22034541646029!3d60.54785031976852!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x468b8a621006b86d%3A0x76bb9a2a98433608!2sLoviisan%20Aitta!5e0!3m2!1sen!2sfi!4v1631790185843!5m2!1sen!2sfi"
              width="100%"
              height="450"
              style="border: 0"
              allowfullscreen=""
              loading="lazy"
            ></iframe>
          </div>
          <div>
            <h3>Loviisan Aitta</h3>
            <p>Vahdontie 600, 21290 Rusko</p>
            <h3>Puhelinnumero</h3>
            <p>(02) 432 2227</p>
            <h3>Sähköpostiosoite</h3>
            <p>helena.aho@loviisanaitta.fi</p>
            <h3>Myymälä avoinna</h3>
            <v-row>
              <v-col cols="12" sm="4" md="10">
                <v-row no-gutters class="ma-0">
                  <v-col> ma-pe </v-col>
                  <v-col> 11-18 </v-col>
                </v-row>
                <v-row no-gutters class="ma-0">
                  <v-col> la </v-col>
                  <v-col> 11-15 </v-col>
                </v-row>
                <v-row no-gutters class="ma-0">
                  <v-col> su </v-col>
                  <v-col> Suljettu </v-col>
                </v-row>
              </v-col>
            </v-row>

            <!-- <h3 class="mt-2">Joulunavaus</h3>
            <v-row>
              <v-col cols="12" sm="4" md="10">
                <v-row no-gutters class="ma-0">
                  <v-col>4.11.</v-col>
                  <v-col>11-15</v-col>
                </v-row>
                <v-row no-gutters class="ma-0">
                  <v-col>5.11.</v-col>
                  <v-col>12-16</v-col>
                </v-row>
              </v-col>
            </v-row> -->

            <h3 class="mt-2">Poikkeuksia aukioloihin</h3>
            <v-row>
              <v-col cols="12" sm="4" md="10">
                <v-row no-gutters class="ma-0">
                  <v-col>29.3.-1.4.</v-col>
                  <v-col>Suljettu</v-col>
                </v-row>
                <v-row no-gutters class="ma-0">
                  <v-col>30.4.</v-col>
                  <v-col>11-15</v-col>
                </v-row>
                <v-row no-gutters class="ma-0">
                  <v-col>1.5.</v-col>
                  <v-col>Suljettu</v-col>
                </v-row>
                <v-row no-gutters class="ma-0">
                  <v-col>9.5.</v-col>
                  <v-col>Suljettu</v-col>
                </v-row>
              </v-col>
            </v-row>
          </div>
        </div>
      </div>
      <div>
        <!-- <h2 class="mb-8 text-center">Henkilökunta</h2> -->
        <div class="d-md-flex" style="justify-content: center">
          <div style="text-align: center" class="px-3">
            <v-img
              width="150"
              height="182"
              class="mx-auto mb-4"
              src="../assets/images/contact/Helena.jpg"
            />
            <h3>Helena Aho</h3>
            <p class="mb-0">Kauppias</p>
            <p class="mb-0">(02) 432 2227</p>
            <p>helena.aho@loviisanaitta.fi</p>
          </div>
          <div style="text-align: center" class="px-3">
            <v-img
              width="150"
              height="auto"
              class="mx-auto mb-4"
              src="../assets/images/services/Elina.jpg"
            />
            <h3>Elina Aho-Mullo</h3>
            <p class="mb-0">Sisustussuunnittelija</p>
            <p class="mb-0">040 5162797</p>
            <p>elina.aho-mullo@loviisanaitta.fi</p>
          </div>
          <!-- <div style="text-align: center">
            <v-img
              align="center"
              width="150"
              class="mx-auto mb-4"
              src="../assets/images/services/Taina.jpg"
            />
            <h3>Taina Karimaa</h3>
            <p class="mb-0">Sisustussuunnittelija</p>
            <p class="mb-0">(02) 4322871</p>
            <p>taina.karimaa@loviisanaitta.fi</p>
          </div> -->
        </div>
      </div>

      <div></div>
    </v-container>
    <foo-ter />
  </div>
</template>

<script>
export default {
  metaInfo() {
    return {
      meta: [
        {
          vmid: "description",
          name: "description",
          content: "Loviisan Aitta Yhteystiedot",
        },
      ],
    };
  },
};
</script>

<style scoped>
h3 {
  font-family: "Lora", sans-serif;
}
</style>
